import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/Animation/FadeInUp'
import React, { memo } from 'react'

export interface Props {
  list?: string[]
  title?: string
}

export const Info = memo(function Info({ list, title }: Props) {
  if (!list) {
    return null
  }

  return (
    <Container>
      <Wrapper>
        {title ? (
          <FadeInUp>
            <Title>{title}</Title>
          </FadeInUp>
        ) : null}

        <FadeInUp>
          <List>
            {list.map((item: any, index) => (
              <Item key={index}>{item.label}</Item>
            ))}
          </List>
        </FadeInUp>
      </Wrapper>
    </Container>
  )
})

const Container = styled.section`
  background: ${({ theme }) => theme.colors.variants.neutralLight4};
  color: ${({ theme }) => theme.colors.variants.neutralDark3};
  padding: 11.75rem 1.5rem 12.5rem;

  @media (max-width: 1199px) {
    padding: 9.375rem 1.5rem 7.5rem;
  }
`

const Wrapper = styled.div`
  max-width: 37.5rem;
  margin: auto;
  text-align: center;

  @media (max-width: 1199px) {
    max-width: none;
  }
`

const Title = styled.h2`
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 2.5rem;
  font-weight: 600;
  line-height: 2.5rem;
  margin-bottom: 1.375rem;
  text-transform: uppercase;

  @media (max-width: 1199px) {
    font-size: 1.875rem;
    line-height: 1.875rem;
    margin-bottom: 1.875rem;
  }
`

const List = styled.div`
  background: url('illustration-11.svg') no-repeat center;
  background-size: contain;
  padding: 1rem 0;

  @media (max-width: 1199px) {
    padding: 0;
  }
`

const Item = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1.3125rem;
  line-height: 1.875rem;
  &:first-of-type {
    &:before {
      display: none;
    }
  }
  &:before {
    content: '';
    display: block;
    width: 0.0625rem;
    height: 1.875rem;
    background: ${({ theme }) => theme.colors.variants.neutralDark3};
    margin: 0.9375rem auto;
  }
`
